import ZStorageEngine from "../../utils/StorageEngine";

const StorageEngine = key => ({
    load() {
        return new Promise((reslove, reject) => {
            reslove(ZStorageEngine(key).load());
        });
    },
    save(state) {
        return new Promise((reslove, reject) => {
            reslove(ZStorageEngine(key).save(state));
        });
    },
    delete() {
        return new Promise((reslove, reject) => {
            reslove(ZStorageEngine(key).delete());
        });
    },
    loadReply() {
        return ZStorageEngine(key).load();
    },
    saveReply(state) {
        ZStorageEngine(key).save(state);
    },
    deleteReply() {
        ZStorageEngine(key).delete();
    },
});
export default StorageEngine;
